import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import axios from 'axios'

import PrimaryButton from "../Button/PrimaryButton";
import LineArrowRight from "../Icons/LineArrowRight"
import DropArrow from "../Icons/DropArrow"

const FormWrap = styled.form`
	padding: 20px 0;
	width: 100%;
	max-width: 960px;
	margin: auto;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	input {
		width: 32%;
		padding: 16px 20px 19px;
		margin-bottom: 26px;
		font-size: 14px;
		line-height: 19px;
		background: #fff;
		border: 1px solid #D0D0D0;
		border-radius: 6px;
		box-shadow: rgba(0, 0, 0, .1);
		&:focus {
			outline: none;
		}
	}

	textarea {
		width: 100%;
		padding: 16px 20px 19px;
		margin-bottom: 26px;
		font-size: 14px;
		line-height: 19px;
		background: #fff;
		border: 1px solid #D0D0D0;
		border-radius: 6px;
		box-shadow: rgba(0, 0, 0, .1);
		resize: none;
		&:focus {
			outline: none;
		}
	}
	.select-wrap {
		position: relative;
		width: 32%;
		z-index: 1;
		svg {
			pointer-events: none;
			position: absolute;
			right: 20px;
			top: 24px;
			z-index: 2;
		}
	}
	select {
		position: relative;
		z-index: 1;
		width: 100%;
		padding: 16px 20px 19px;
		margin-bottom: 26px;
		color: #938888;
		font-size: 14px;
		line-height: 19px;
		background: #fff;
		border: 1px solid #D0D0D0;
		border-radius: 6px;
		box-shadow: rgba(0, 0, 0, .1);
		webkit-appearance: none;
  	moz-appearance: none;
  	appearance: none;
		&:focus {
			outline: none;
		}
		
	}
`

class ColorOptionsForm extends React.Component {
	constructor(props) {
		super(props)
		this.state={
		full_name: '',
		email: '',
		phone: '',
		zipcode: '',
		colorOption: '',
		message: '',
		agreeViaSms: true,
		}
		this.handleChange = this.handleChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
	}
  	componentDidMount() {
		if (typeof window !== 'undefined') 
		{
			const searchParams = new URLSearchParams(window.location.search);
			if(searchParams.get("utm_source")!='' && searchParams.get("utm_source")!=null)
			{
				sessionStorage.setItem('utm_source', searchParams.get("utm_source"));
				sessionStorage.setItem('utm_medium', searchParams.get("utm_medium"));
				sessionStorage.setItem('utm_campaign', searchParams.get("utm_campaign"));
				sessionStorage.setItem('utm_term', searchParams.get("utm_term"));
				sessionStorage.setItem('utm_content', searchParams.get("utm_content"));
			}
		}
	}
	handleChange(event) {
		const {id, value} = event.target
		if (id !== "agreeViaSms") {
		this.setState({
			[id]: value,
		})
		} else {
		this.setState({
			[id]: event.target.checked,
		})
		}
	}
	handleSubmit(event) {
		let utm_source_val = 'website';
		let utm_medium_val = '';
		let utm_campaign_val = '';
		let utm_term_val = '';
		let utm_content_val = '';
		if(sessionStorage.getItem('utm_source')!='' && sessionStorage.getItem('utm_source')!=null) 
		{
		utm_source_val = sessionStorage.getItem('utm_source');
		utm_medium_val = sessionStorage.getItem('utm_medium');
		utm_campaign_val = sessionStorage.getItem('utm_campaign');
		utm_term_val = sessionStorage.getItem('utm_term');
		utm_content_val = sessionStorage.getItem('utm_content');
		}
		event.preventDefault()
		const { location } = this.props;
		const comment ="Color Options: " + this.state.colorOption + "; Message: " + this.state.message + ", SMS consent : " + this.state.agreeViaSms;
		const data = {
			source: location.origin,
			form: "Color Options Form",
			page: location.pathname,
			first_name: this.state.full_name,
			email: this.state.email,
			phone_no: this.state.phone,
			zipcode: this.state.zipcode,
			comment: comment,
			utm_medium: utm_medium_val,
			utm_term: utm_term_val,
			utm_campaign: utm_campaign_val,
			utm_content: utm_content_val,
			lead_source: utm_source_val,
		}
			
		axios({
		method: "post",
		url: "https://crm.senseicrm.com/api/create-lead-wordpress",
		data: data,
		headers: { Authorization: "Bearer " + process.env.GATSBY_FORM_CRM_TOKEN }
		}).then((res) => {
		this.setState({ full_name: '', email: '', phone: '', zipcode: '', message: '', colorOption: '', agreeViaSms: false});
		navigate("/thank-you-color-option");
		}).catch((error) => {
			alert(error)
		});
	}
  render() {
		return(
			<FormWrap id="ColorOptionsForm" onSubmit={this.handleSubmit}>
				<input type="text" name="full_name" id="full_name" onChange={this.handleChange} placeholder="Full Name" pattern="^(?!\s*$)[A-Za-z\s]+$" required />
				<input type="email" name="email" id="email" onChange={this.handleChange} placeholder="Email ID" required />
				<input type="number" name="phone" id="phone" onChange={this.handleChange} placeholder="Contact Number" pattern="[0-9]{10}" required />
				<input type="text" name="zipcode" id="zipcode" onChange={this.handleChange} placeholder="Zip Code" pattern="^[0-9]{5}$"  required />
				<div className="select-wrap">
					<DropArrow fill="#574C4C" />
					<select name="colorOption" id="colorOption" onChange={this.handleChange}>
						<option value="">Color Options</option>
						<option value="1">1</option>
						<option value="2">2</option>
						<option value="3">3</option>
						<option value="4">4</option>
					</select>
				</div>
				<input type="hidden" />
				<textarea row="5" name="message" id="message" onChange={this.handleChange} placeholder="Message" required />
				<button type='submit'>
          <PrimaryButton text="Submit" icon={<LineArrowRight />} />
        </button>
	 		</FormWrap>
		)
	}
}

export default ColorOptionsForm